@font-face {
  font-family: "GilroyRegular";
  font-display: auto;
  src: url("./shared/fonts/Gilroy-Regular.ttf") format("truetype")
}

@font-face {
  font-family: "GilroyMedium";
  font-display: auto;
  src: url("./shared/fonts/Gilroy-Medium.ttf") format("truetype")
}

@font-face {
  font-family: "GilroySemiBold";
  font-display: auto;
  src: url("./shared/fonts/Gilroy-SemiBold.ttf") format("truetype")
}

@font-face {
  font-family: "GilroyBlack";
  font-display: auto;
  src: url("./shared/fonts/Gilroy-Black.ttf") format("truetype")
}

@font-face {
  font-family: "MontserratSemiBold";
  font-display: auto;
  src: url("./shared/fonts/Montserrat-SemiBold.ttf") format("truetype")
}

@font-face {
  font-family: "GilroyLight";
  font-display: auto;
  src: url("./shared/fonts/Gilroy-Light.ttf") format("truetype")
}

html {
  scroll-behavior: smooth;
}